.container {
    height: 100vh;
    overflow: hidden;
    border-right: 1px solid #aaa;

    .navigationInnerContainer {
        overflow: scroll;
        width: 280px;
        
        .logoNameContainer {
            padding-left: 5px;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #333;
            text-decoration: none;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
           
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: #000;
            }

            .siteNameContainer {
            
                margin-left: 10px;

                .mainName {
                    font-size: 24px;
                    font-weight: bold;
                    color: #000;
                }
                .secondaryName {
                    color: #333;
                    font-weight: bolder;
                }
            }
        }

        .navlinksOuterContainer {
       
            height: calc(100vh - 85px);
            overflow: hidden;

            .infoContainer {
        
                background-color: aliceblue;
                padding: 15px;
                margin: 15px;
                text-align: center;
                border-radius: 5px;

                .userNameContainer,
                .emailContainer {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: a000;
                    font-size: 18px;
                }
                
                .userNameContainer{
                    border-bottom: 1px solid #333;
                    font-weight: bolder;  
                    font-size: 25px;
                }
            }

            .navlinksContainer {

                height: calc(100vh - 220px);
                overflow: auto;

                .navlink {
                    display: flex;
                    gap: 15px;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 15px;
                    text-decoration: none;
                    color: #000;
                    font-weight: bolder;
                }
            }
        }
    }
}
