.container {
    height: 100vh;
    overflow: hidden;
   

    display: flex;
    justify-content: stretch;
    align-items: stretch;

    .navigationContainer {
        height: 100%;
        flex: 0 0 280px;
      
        overflow: hidden;
        transition: flex 0.3s ease;
    }

    .contentContainer {
      
        width: 100%;
        overflow: auto;
        padding: 15px;

        .menuIcon {
            padding: 10px;
            background-color: #212134;
            color: #fff;
            font-size: 20px;

            &:hover {
                cursor: pointer;
                background-color: #000;
            }
        }

        .contentInnerContainer {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .routesContainer {
                width: 100%;
                max-width: 1920px;
            }
        }
    }
}
