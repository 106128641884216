@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

*,
::before,
::after {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4a45aa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a45ff;
}

.highlighted-row {
  background-color: yellow;
}

.shadow-custom {
  box-shadow:
    0 2px 4px -1px rgba(0, 0, 0, 0.8),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    0 5px 10px -3px rgba(0, 0, 0, 0.8);
}

:root {
  --primary-bg-color: #fbfbfb;
  --primary-text-color: #04266d;
  --secondary-text-color: #000000;
   --primary-color: #04266d;
}
